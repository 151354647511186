import { useEffect, useState } from 'react';
import apiClient from '../../utils/API';
import Dropdown from '../Dropdown';
import DropdownSearch from '../DropdownSearch';
import AnswerLock from './status/AnswerLock';

/**
 * presents an answer response as a form field with selectable options
 * 
 * @param {*} param0 
 */
function DropdownAnswer({answer, disabled, label, ...props}){

    const [items, setItems] = useState([]);

    useEffect(async ()=>{
       
        if (!answer.Year)
            return; //not ready to specify answer yet
  
        const options = await apiClient.getOptionsAsListItems(answer.QuestionKey, answer.Year);
        setItems(options);
 
    },[answer.Year]);

    const isSearchable =  items.length > 20;
    const isDisabled = disabled || answer.IsLocked;

    const fullLabel = (
        <>
            {label}
            <AnswerLock answer={answer} />
        </>
    );

    if (isSearchable)
        return <DropdownSearch listItems={items} name={answer.FieldKey} disabled={isDisabled} inputLabel={fullLabel} {...props} /> 
    
    return <Dropdown listItems={items} name={answer.FieldKey} disabled={isDisabled} label={fullLabel}  {...props} />
}

export default DropdownAnswer;