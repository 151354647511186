import { useSelector } from "react-redux";
import { useEffect, useState, useRef } from "react";
import { useLocation } from 'react-router-dom';
import EditBasicInfo from "./EditBasicInfo";
import EditContactInfo from "./EditContactInfo";
import EditAboutInfo from "./EditAboutInfo";
import EditAccountInfo from "./EditAccountInfo";
import EditHighschoolInfo from "../field-groups/EditHighschool";
import PortalView from "../../components/Global/PortalView";
import Checkbox from "./../../components/ReadOnlyCheckbox";
import ReadOnly from "./../../components/ReadOnly";
import PrintIcon from "../../components/PrintIcon";
import { FIELDS } from "../../store/form/fields";
import ReadOnlyField from "../../components/ReadOnlyField";
import ConvertCode from "../../components/Utility/ConvertCode";
import FormSection from "../../components/FormSection";
import auth from "./../../auth";
import _get from "lodash.get";
import SectionFormHelper from "../../utils/helper/SectionHelper";
import { getReduxField } from "../../utils";
import moment from "moment";
import SectionStateIcon from "../../components/progress/SectionStateIcon";
import apiClient from "../../utils/API";
import ProgramInfo from "../../utils/dtos/ProgramInfo";
import ProgramApplicationInfo from "../../utils/dtos/ProgramApplicationInfo";



/**
 * render the primary content of the Profile page
 * @returns {Component}
 */
function Profile() {

    const formState = useSelector((state) => state.form);
    const location = useLocation();
    const anchor = location.hash;
    useEffect(() => {
        if (anchor) {
            const element = document.querySelector(anchor);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [anchor]);

    // const history = useHistory();

    const [email, setEmail] = useState("");

    const sectionGroup = new SectionFormHelper(useState());

    const basicInfoState = useState(null);
    const basicInfo = sectionGroup.create(basicInfoState, useRef(null), [
        FIELDS.FIRST_NAME,
        FIELDS.LAST_NAME,
        FIELDS.MIDDLE_INITIALS,
        FIELDS.BIRTHDATE,
        FIELDS.OTHER_NAMES,
        FIELDS.GENDER__MALE,
        FIELDS.GENDER__FEMALE,
        FIELDS.GENDER__NON_BINARY,
        FIELDS.GENDER__OTHER,
        FIELDS.GENDER__TRANSMAN,
        FIELDS.GENDER__TRANSWOMAN,
        FIELDS.GENDER__NA,
        FIELDS.PRONOUNS__FEMALE,
        FIELDS.PRONOUNS__MALE,
        FIELDS.PRONOUNS__NEUTRAL,
        FIELDS.PRONOUNS__OTHER,
        FIELDS.CERTIFY_IDENTITY,
    ]);

    const contactInfoState = useState(null);
    const contactInfo = sectionGroup.create(contactInfoState, useRef(null), [
        FIELDS.HOME_PHONE,
        FIELDS.ALTERNATE_PHONE,
        FIELDS.PERMANENT_ADDRESS,
        FIELDS.MAILING_ADDRESS,
        FIELDS.PRIMARY_CONTACT,
        FIELDS.PRIMARY_PHONE_CELL_FLAG,
        FIELDS.ALTERNATE_PHONE_CELL_FLAG,
    ]);

    const aboutInfoState = useState(null);
    const aboutInfo = sectionGroup.create(aboutInfoState, useRef(null), [
        FIELDS.SSN,
        FIELDS.HISPANIC,
        FIELDS.ETHNICITY__AM_INDIAN,
        FIELDS.ETHNICITY__ASIAN,
        FIELDS.ETHNICITY__BLACK,
        FIELDS.ETHNICITY__HAWAIIAN_PI,
        FIELDS.ETHNICITY__WHITE,
        FIELDS.ETHNICITY__NA,
        FIELDS.ETHNICITY__OTHER,
        FIELDS.OTSG__ENROLLED_TRIBE,
        FIELDS.HAS_DEPENDENT_CHILDREN,
        FIELDS.IS_NATIONAL_GUARD_MEMBER,
        FIELDS.IS_TEACHER_CANDIDATE_MEMBER,
        FIELDS.FOSTER_CARE,
        FIELDS.TEST_APPLICATION,
        FIELDS.OREGON_RESIDENT,
        FIELDS.EARLY_LEARNING_MAJOR,
        FIELDS.FAFSA_FILING_DATE,
        FIELDS.IS_TEACHER_CANDIDATE_MEMBER
    ]);

    const highSchoolState = useState(null);
    const highSchool = sectionGroup.create(highSchoolState, useRef(null), [
        FIELDS.ASPIRE__MEMBER,
        FIELDS.ASPIRE__ADVISOR,
        FIELDS.ASPIRE__SITE,
        FIELDS.CORRECTIONAL_FACILITY_HS,
        FIELDS.CORRECTIONAL_FACILITY_CC,
        FIELDS.CORRECTIONAL_FACILITY_TYPE,
        FIELDS.CORRECTIONAL_FACILITY_RELEASE_DATE,
        FIELDS.CORRECTIONAL_FACILITY_NAME,
        FIELDS.CORRECTIONAL_FACILITY_VERIFICATION_ALLOW,
        FIELDS.HIGHSCHOOL_GPA,
        FIELDS.ACT,
        FIELDS.SAT,
        FIELDS.HIGHSCHOOL_GRAD_DATE,
        FIELDS.FIFTH_YEAR_SENIOR,
        FIELDS.HIGHSCHOOL,
        FIELDS.HIGHSCHOOL_TYPE,
        FIELDS.GED_SCORE,
        FIELDS.GED_IN_PROGRESS_FLAG,
    ], "High School");
    highSchool.isCompletionVerbose = true;

    const accountInfoState = useState(null);
    const accountInfo = sectionGroup.create(accountInfoState, useRef(null), [
        FIELDS.OSAC_REFERRAL,
        FIELDS.PRIVACY_AGREEMENT,
        FIELDS.PUBLICITY_RELEASE,
    ]);


    const [jumpState, setJumpState] = useState(null);
    const applicationRef = useRef(null);

    for (let section of sectionGroup.sections) {
        section.onSave = () => setJumpState("WAIT");
        section.onView = () => setJumpState("WAIT");
    }

    useEffect(() => {
        //wait one render before doing the jump
        if (jumpState == "WAIT")
            setJumpState("READY");

        else if (jumpState == "READY") {


            var nextSection = sectionGroup.recommendedSection;
            if (nextSection == null)
                applicationRef.current.scrollIntoView();
            else
                sectionGroup.recommendedSection.ref.current.scrollIntoView();


            setJumpState(null);
        }
    }, [jumpState, basicInfoState, contactInfoState, aboutInfoState, highSchoolState, accountInfoState]);

    const isProfileComplete = sectionGroup.isComplete  /* && isPrivacyAgreement*/;

    /**
     * populate initial answers and related state
     */
    useEffect(async () => {

        const [userEmail, answerData] = await Promise.all([
            auth.getUserEmail(), // this method may be async in the future to get from graph api, as getting from token relies on having the email user claim enabled in b2c.
            sectionGroup.fetchState()
        ]);
        setEmail(userEmail);
    }, []);
 
    const [scholarshipProgramData, setScholarshipProgramData] = useState(null);
    const [scholarshipApplicationData, setScholarshipApplicationData] = useState(null);
    const scholarshipApp = new ProgramApplicationInfo(scholarshipApplicationData, scholarshipProgramData);

    useEffect(() => {
        (async() =>{
            var programData = await apiClient.getProgram("SCH"); 
            
            var recentYear = new ProgramInfo(programData).recentSeason?.year;          
            var scholarshipApp = await apiClient.getApplication("SCH", recentYear);
            
            setScholarshipProgramData(programData);
            setScholarshipApplicationData(scholarshipApp);
            
        })();
    }, []);


    const get = (fieldKey) => formState.fields[fieldKey]?.value;
    const getNames = (fieldKey) => {
        const stateObject = formState.fields[fieldKey];
        let string = "";
        for (const i in stateObject) {
            string +=
                stateObject[i].FirstName.value +
                " " +
                stateObject[i].LastName.value +
                "\n";
        }
        return string;
    };

    const getAddress = (fieldKey) => {
        const street = formState.fields[fieldKey]?.Address.value
            ? formState.fields[fieldKey]?.Address.value
            : "";
        const city = formState.fields[fieldKey]?.CityId.value
            ? formState.fields[fieldKey]?.CityId.value
            : "";
        const county = formState.fields[fieldKey]?.CountyId.value
            ? formState.fields[fieldKey]?.CountyId.value
            : "";
        const zip = formState.fields[fieldKey]?.Zip.value
            ? formState.fields[fieldKey]?.Zip.value
            : "";

        return { street, city, county, zip };
    };

    const getSchoolCode = (fieldKey) => {
        return formState.fields[fieldKey]?.SchoolCode?.value;
    };


    return (
        <PortalView>
            <div name="ProfilePage">
                <div className="page-intro">
                    <div className="wrapper">
                        <div className="page-intro__layout">
                            <div className="page-intro__icon">
                                <img src="/Profile.svg" alt="Profile" />
                            </div>
                            <div className="page-intro__content rhythm">
                                <h1 className="heading heading--h1"> Profile <PrintIcon />
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="form-wrapper rhythm">
                    <div ref={basicInfo.ref}>
                        {!basicInfo.isEditable ?
                            <FormSection
                                title={`Basic Information`}
                                status={<SectionStateIcon section={basicInfo} />}
                                isEditable={false}
                                onEditClick={() => basicInfo.edit()}
                            >
                                <div className="form-section__fields">
                                    <ReadOnlyField
                                        name="name"
                                        value={get(FIELDS.FIRST_NAME) + " " + get(FIELDS.LAST_NAME)}
                                    />
                                    <ReadOnlyField
                                        name="other names used on submitted documents"
                                        value={getNames(FIELDS.OTHER_NAMES)}
                                    />
                                    <ReadOnlyField name="Birthdate" value={get(FIELDS.BIRTHDATE)} />

                                    <div container className="field-wrapper">
                                        <div className="field-wrapper__title">gender identity</div>
                                        <ReadOnly
                                            name="Gender"
                                            value={
                                                (get(FIELDS.GENDER__MALE) === true ? "Male\n" : "") +
                                                (get(FIELDS.GENDER__FEMALE) === true
                                                    ? "Female\n"
                                                    : "") +
                                                (get(FIELDS.GENDER__TRANSMAN) === true
                                                    ? "Trans Male/Trans Man\n"
                                                    : "") +
                                                (get(FIELDS.GENDER__TRANSWOMAN) === true
                                                    ? "Trans Female/Trans Woman\n"
                                                    : "") +
                                                (get(FIELDS.GENDER__NON_BINARY) === true
                                                    ? "Non-binary/Genderqueer/Gender non-conforming\n"
                                                    : "") +
                                                (get(FIELDS.GENDER__OTHER) === true
                                                    ? "Different Identity"
                                                    : "")
                                            }
                                        />
                                    </div>

                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">pronouns</div>
                                        <ReadOnly
                                            name="Pronouns"
                                            value={
                                                (get(FIELDS.PRONOUNS__MALE) === true
                                                    ? "He/Him/His\n"
                                                    : "") +
                                                (get(FIELDS.PRONOUNS__FEMALE) === true
                                                    ? "She/Her/Hers\n"
                                                    : "") +
                                                (get(FIELDS.PRONOUNS__NEUTRAL) === true
                                                    ? "They/Them/Their\n"
                                                    : "") +
                                                (get(FIELDS.PRONOUNS__OTHER) !== null
                                                    ? "Other: " + get(FIELDS.PRONOUNS__OTHER) + "\n"
                                                    : "")
                                            }
                                        />
                                    </div>

                                    <div className="field-wrapper add-bottom-padding">
                                        <div className="field-wrapper__title">
                                            identity certification
                                        </div>
                                        <div>
                                            <Checkbox
                                                useController={false}
                                                name="CertifyIdentity"
                                                value={
                                                    get(FIELDS.CERTIFY_IDENTITY) === "True" || get(FIELDS.CERTIFY_IDENTITY) ? true : false
                                                }
                                                disabled
                                                label="I certify that I am the person named above."
                                            />
                                        </div>
                                    </div>
                                </div>
                            </FormSection>
                            :
                            <EditBasicInfo
                                section={basicInfo}
                                onChange={() => basicInfo.save()}
                                onCancel={() => basicInfo.view()}
                            />
                        }
                    </div>
                    {/* End BasicInfo */}

                    <div ref={contactInfo.ref} id="contact-info" >
                        {!contactInfo.isEditable ? (
                            <FormSection
                                title="Contact Information"
                                status={<SectionStateIcon section={contactInfo} />}
                                isEditable={false}
                                onEditClick={() => contactInfo.edit()}
                            >
                                <div name="ContactInfoForm">
                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">email</div>
                                        <ReadOnly name="Email" value={email} />
                                    </div>

                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">phone number</div>
                                        <ReadOnly
                                            name="PhoneNumber"
                                            value={
                                                (get(FIELDS.ALTERNATE_PHONE)
                                                    ? get(FIELDS.ALTERNATE_PHONE) + " (Mobile)\n"
                                                    : "") +
                                                (get(FIELDS.HOME_PHONE)
                                                    ? get(FIELDS.HOME_PHONE) + " (Home)\n"
                                                    : "")
                                            }
                                        />
                                    </div>

                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">permanent address</div>
                                        <ReadOnly
                                            name="PermanentAddress"
                                            value={
                                                <ConvertCode
                                                    isAddress={true}
                                                    address={getAddress(FIELDS.PERMANENT_ADDRESS)}
                                                />
                                            }
                                        />
                                    </div>

                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">mailing address</div>
                                        {getAddress(FIELDS.MAILING_ADDRESS) && (
                                            <ReadOnly
                                                name="MailingAddress"
                                                value={
                                                    <ConvertCode
                                                        isAddress={true}
                                                        address={getAddress(FIELDS.MAILING_ADDRESS)}
                                                    />
                                                }
                                            />
                                        )}
                                    </div>

                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">Additional Contacts</div>
                                        <ReadOnly
                                            name="primary contact"
                                            value={
                                                <ConvertCode
                                                    isContact={true}
                                                    contact={formState.fields[FIELDS.PRIMARY_CONTACT] !== null ? formState.fields[FIELDS.PRIMARY_CONTACT] : ''}
                                                />
                                            }
                                        />
                                    </div>
                                </div>
                            </FormSection>
                        ) : (
                            <EditContactInfo
                                section={contactInfo}
                                onChange={() => contactInfo.save()}
                                onCancel={() => contactInfo.view()}
                            />
                        )}
                    </div>
                    {/* End ContactInfo */}

                    <div ref={aboutInfo.ref}>
                        {!aboutInfo.isEditable ? (
                            <FormSection
                                title="About Me"
                                status={<SectionStateIcon section={aboutInfo} />}
                                isEditable={false}
                                onEditClick={() => aboutInfo.edit()}
                            >
                                <div name="AboutInfoForm">
                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">social security number</div>
                                        <ReadOnly
                                            name="SSN"
                                            value={get(FIELDS.SSN) ? "*********" : ""}
                                        />
                                    </div>

                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">Are you hispanic or latino/a/x?</div>
                                        <ReadOnly
                                            name="Hispanic"
                                            value={
                                                get(FIELDS.HISPANIC) === "HISPAN"
                                                    ? "Yes"
                                                    : get(FIELDS.HISPANIC) === "NONHISPAN"
                                                        ? "No"
                                                        : "Choose not to say"
                                            }
                                        />
                                    </div>

                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">Racial group or ethnicity</div>
                                        <ReadOnly
                                            name="Ethnicity"
                                            value={
                                                (get(FIELDS.ETHNICITY__AM_INDIAN) === true
                                                    ? "American Indian or Alaska Native\n"
                                                    : "") +
                                                (get(FIELDS.ETHNICITY__ASIAN) === true
                                                    ? "Asian\n"
                                                    : "") +
                                                (get(FIELDS.ETHNICITY__BLACK) === true
                                                    ? "Black or African-American\n"
                                                    : "") +
                                                (get(FIELDS.ETHNICITY__HAWAIIAN_PI) === true
                                                    ? "Native Hawaiian or Pacific Islander\n"
                                                    : "") +
                                                (get(FIELDS.ETHNICITY__WHITE) === true
                                                    ? "White\n"
                                                    : "") +
                                                (get(FIELDS.ETHNICITY__NA) === true
                                                    ? "Choose not to say\n"
                                                    : "")
                                            }
                                        />
                                    </div>

                      
                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">applicant type</div>
                                        <ReadOnly
                                            name="ApplicantType"
                                            value={
                                                get(FIELDS.TEST_APPLICATION) === "True" ||
                                                    get(FIELDS.TEST_APPLICATION) === true
                                                    ? "Test Application"
                                                    : get(FIELDS.TEST_APPLICATION) === "False" ||
                                                        get(FIELDS.TEST_APPLICATION) === false
                                                        ? "Student Application"
                                                        : ""
                                            }
                                        />
                                    </div>

                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">
                                            Oregon resident for 12 months preceding enrollment?
                                        </div>
                                        <ReadOnly
                                            name="Resident"
                                            value={
                                                get(FIELDS.OREGON_RESIDENT) === "True" ||
                                                    get(FIELDS.OREGON_RESIDENT) === true
                                                    ? "Yes"
                                                    : get(FIELDS.OREGON_RESIDENT) === "False" ||
                                                        get(FIELDS.OREGON_RESIDENT) === false
                                                        ? "No"
                                                        : ""
                                            }
                                        />
                                    </div>

                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">
                                            I have dependent children.
                                        </div>
                                        <ReadOnly
                                            name="Resident"
                                            value={
                                                get(FIELDS.HAS_DEPENDENT_CHILDREN) === "True" ||
                                                    get(FIELDS.HAS_DEPENDENT_CHILDREN) === true
                                                    ? "Yes"
                                                    : get(FIELDS.HAS_DEPENDENT_CHILDREN) === "False" ||
                                                        get(FIELDS.HAS_DEPENDENT_CHILDREN) === false
                                                        ? "No"
                                                        : ""
                                            }
                                        />
                                    </div>

                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">Enrolled tribe status</div>
                                        <ReadOnly
                                            name="Tribe"
                                            value={
                                                Number(get(FIELDS.OTSG__ENROLLED_TRIBE)) === 0 ? "I am not enrolled in a tribe" :
                                                    Number(get(FIELDS.OTSG__ENROLLED_TRIBE)) > 0 ? "I am enrolled in a tribe" :
                                                        "Other Non-Listed Tribe"
                                            }
                                        />
                                    </div>

                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">
                                            I am currently serving in the Oregon National Guard.
                                        </div>
                                        <ReadOnly
                                            name="Resident"
                                            value={
                                                get(FIELDS.IS_NATIONAL_GUARD_MEMBER) === "True" ||
                                                    get(FIELDS.IS_NATIONAL_GUARD_MEMBER) === true
                                                    ? "Yes"
                                                    : get(FIELDS.IS_NATIONAL_GUARD_MEMBER) === "False" ||
                                                        get(FIELDS.IS_NATIONAL_GUARD_MEMBER) === false
                                                        ? "No"
                                                        : ""
                                            }
                                        />
                                    </div>

                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">
                                        I will be enrolled in a TSPC approved Educator Preparation Program that results in 1) Preliminary teacher licensure, OR 2) School Personnel licenses, including school counseling, social work, and school psychology.
                                        </div>
                                        <ReadOnly
                                            name="Resident"
                                            value={
                                                get(FIELDS.IS_TEACHER_CANDIDATE_MEMBER) === "True" ||
                                                    get(FIELDS.IS_TEACHER_CANDIDATE_MEMBER) === true
                                                    ? "Yes"
                                                    : get(FIELDS.IS_TEACHER_CANDIDATE_MEMBER) === "False" ||
                                                        get(FIELDS.IS_TEACHER_CANDIDATE_MEMBER) === false
                                                        ? "No"
                                                        : ""
                                            }
                                        />
                                    </div>

                                    <div container className="field-wrapper">
                                        <div className="field-wrapper__title">
                                            I am currently or formerly in foster care or participating in an independent living program.
                                        </div>
                                        <ReadOnly
                                            name="FosterStatus"
                                            value={
                                                get(FIELDS.FOSTER_CARE) === "True" ||
                                                    get(FIELDS.FOSTER_CARE) === true
                                                    ? "Yes"
                                                    : get(FIELDS.FOSTER_CARE) === "False" ||
                                                        get(FIELDS.FOSTER_CARE) === false
                                                        ? "No"
                                                        : ""
                                            }
                                        />
                                    </div>
                                    <div container className="field-wrapper">
                                        <div className="field-wrapper__title">
                                            My degree will be related to working with or supporting work in the field of Early Learning and Care.
                                        </div>
                                        <ReadOnly
                                            name="EarlyLearningStatus"
                                            value={
                                                get(FIELDS.EARLY_LEARNING_MAJOR) === "True" ||
                                                    get(FIELDS.EARLY_LEARNING_MAJOR) === true
                                                    ? "Yes"
                                                    : get(FIELDS.EARLY_LEARNING_MAJOR) === "False" ||
                                                        get(FIELDS.EARLY_LEARNING_MAJOR) === false
                                                        ? "No"
                                                        : ""
                                            }
                                        />
                                    </div>
                                </div>
                            </FormSection>
                        ) : (
                            <EditAboutInfo
                                section={aboutInfo}
                                onChange={() => aboutInfo.save()}
                                onCancel={() => aboutInfo.view()}
                            />
                        )}
                    </div> {/* End AboutInfo */}

                    <div ref={highSchool.ref}>
                        {!highSchool.isEditable ? (
                            <FormSection
                                title="High School Information"
                                status={<SectionStateIcon section={highSchool} />}
                                isEditable={false}
                                onEditClick={() => highSchool.edit()}
                            >
                                <div name="HighschoolInfoForm">
                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">
                                            type of high school or equivalent
                                        </div>
                                        <ReadOnly
                                            name="HighschoolType"
                                            value={
                                                <ConvertCode
                                                    codeType="HSTYPE"
                                                    code={get(FIELDS.HIGHSCHOOL_TYPE) ?
                                                        get(FIELDS.HIGHSCHOOL_TYPE) :
                                                        "No High School type received"
                                                    }
                                                />
                                            }
                                        />
                                    </div>

                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">high school</div>
                                        <ReadOnly
                                            name="Highschool"
                                            value={
                                                getSchoolCode(FIELDS.HIGHSCHOOL) ?
                                                    <ConvertCode
                                                        isHS={true}
                                                        code={getSchoolCode(FIELDS.HIGHSCHOOL)}
                                                    /> :
                                                    formState.fields[FIELDS.HIGHSCHOOL]?.UnlistedName?.value ?
                                                        formState.fields[FIELDS.HIGHSCHOOL]?.UnlistedName?.value :
                                                        "No High School information received"
                                            }
                                        />
                                    </div>

                                    <div className="field-wrapper">
                                        <div className="field-wrapper__title">
                                            Date of high school completion or graduation
                                        </div>
                                        <ReadOnly
                                            name="Grad-Highschool"
                                            value={get(FIELDS.HIGHSCHOOL_GRAD_DATE) ?
                                                moment(get(FIELDS.HIGHSCHOOL_GRAD_DATE)).format('MM-YYYY') :
                                                "No High School Graduation date received"
                                            }
                                        />
                                    </div>

                                </div>
                            </FormSection>
                        ) : (
                            <EditHighschoolInfo
                                section={highSchool}
                                hideCorrectional={false}
                                onChange={() => highSchool.save()}
                                onCancel={() => highSchool.view()}
                            />
                        )}
                    </div>

                    <div ref={accountInfo.ref}>
                        {!accountInfo.isEditable ? (
                            <FormSection
                                title="Account Info, Password, Privacy"
                                status={<SectionStateIcon section={accountInfo} />}
                                isEditable={false}
                                onEditClick={() => accountInfo.edit()}
                            >

                                <div className="field-wrapper">
                                    <div className="field-wrapper__title">
                                        HOW DID YOU HEAR ABOUT OSAC GRANTS AND SCHOLARSHIPS?
                                    </div>
                                    <ReadOnly
                                        name="ScholarshipReference"
                                        value={
                                            <ConvertCode
                                                codeType="APPLEARN"
                                                code={get(FIELDS.OSAC_REFERRAL)}
                                            />
                                        }
                                    />
                                </div>

                                <div className="field-wrapper highlight">
                                    <div className="field-wrapper__title">Certification<span style={{ color: 'red' }}>*</span></div>
                                    <div className="field-wrapper__content rhythm">
                                        <div className="rhythm">
                                            <Checkbox value={getReduxField(FIELDS.PRIVACY_AGREEMENT)} disabled={false} required={true} label="This certification takes the place of a signature. By checking this box, you certify that you have read, understood, and agree to the following statements:" />
                                            <ul className="list list--checklist">
                                                <li className="li"> I certify the accuracy of my application(s) and all accompanying documents and, if requested, agree to provide proof of this information.</li>
                                                <li className="li"> I give permission to the Oregon Office of Student Access and Completion (OSAC) to request and use data from my Free Application for Federal Student Aid (FAFSA) or Oregon Student Aid Application (ORSAA).</li>
                                                <li className="li"> I understand that by providing my contact information, I am giving permission to receive application status updates or general reminders.</li>
                                                <li className="li"> OSAC cannot guarantee applicants will receive OSAC grants or scholarships. I agree to hold harmless, defend, and indemnify OSAC for any acts, failures to act, or omissions of OSAC, its employees, agents, volunteers, or any State of Oregon employee.</li>
                                                <li className="li"> I authorize OSAC to share my application information with high school and college officials for additional financial and educational information consistent with federal and state laws. Information may also be shared with the Department of Human Services (DHS) for the Chafee ETG, childcare providers for the Child Care Grant, Oregon Tribes for the Oregon Student Tribal Grant, Oregon National Guard officials for the Oregon National Guard State Tuition Assistance program, Educator Advancement Council (EAC) for the Oregon Teachers Scholars Program, and scholarship selection committees and donors. I understand that data may be shared through the Oregon Financial Aid Exchange (OFAX). I also allow my information to be shared with OSAC-approved researchers.</li>
                                                <li className="li"> Under the Privacy Act of 1974, I understand that I am not required to provide my Social Security Number, however, if I don’t provide it, I understand I will not be considered for OSAC grants or scholarships that require that information. I also understand that if I have not completed the FAFSA/ORSAA, I will not be considered for OSAC need-based grants or scholarships.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                                <div className="field-wrapper highlight">
                                    <div className="field-wrapper__title"> Publicity Release<br />(optional)</div>
                                    <div className="field-wrapper__content rhythm">
                                        <Checkbox value={getReduxField(FIELDS.PUBLICITY_RELEASE)} disabled={false} label="If selected to receive an OSAC grant or scholarship award, I give permission to OSAC to publicize the award through a press release, media story, report, or other promotional materials." />
                                    </div>
                                </div>

                            </FormSection>
                        ) : (
                            <EditAccountInfo
                                section={accountInfo}
                                onChange={() => accountInfo.save()}
                                onCancel={() => accountInfo.view()}
                            />
                        )}
                    </div> {/* End AccountInfo */}

                    <div className="component" ref={applicationRef} >
                        <div className="wrapper rhythm text text--align-center">
                            <a href="/dashboard" className="button" disabled={!isProfileComplete}>Continue to Grants</a>
                            <a href={`/additional-scholarship-info/${scholarshipApp.year}`} className="button" 
                                disabled={!(isProfileComplete && (scholarshipApp.isSubmitted || scholarshipApp.isSeasonOpen))}>Continue to Scholarships</a>
                            {!isProfileComplete &&
                                <div>You must complete your profile questions before proceeding to applications.</div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </PortalView>
    );
}

export default Profile;
