import { useEffect, useState } from 'react';
import apiClient from '../../utils/API';
import moment from 'moment';
import { UNLISTED_COLLEGECODE } from "../../store/form/fields";

 
const FetchingPromises = new Map();

async function fetchValue(url) {
    const key = url;  
    if (FetchingPromises.has(key)) {
        // If a fetch is already in progress, return the existing Promise
        return FetchingPromises.get(key);
    }

    const promise = new Promise(async (resolve, reject) => {
        try {
        const result = await apiClient.get(url);       
        resolve(result);
        } catch (error) {
        FetchingPromises.delete(key); // Remove the promise from the map
        reject(error);
        }
    });

    FetchingPromises.set(key, promise);
    return promise;
}

export const getHS = async (code) => {
  const highschool = await fetchValue(`/high-school/${code}`);
  return highschool?.Name || '';
};

export const getCollege = async (code) => {
  const college = await fetchValue(`/college/${code}`);
  return college?.Name || '';
};

/**
 * get human readable message for given code
 * @param {string} codeType 
 * @param {string} code 
 * @returns string
 */
export const getCode = async (codeType, code) => {
    if (!code || code === '') {
        return null;
    }

    const codeKey = `${codeType}/${code}`;
    const codeInfo = await fetchValue(`/code?code=${code}&codeType=${codeType}`);    
    return codeInfo?.Message ?? ""; 
};

export const getCity = async (cityId) => {
  if (!cityId) {
    return;
  }
  const city = await fetchValue(`/city/${cityId}`);
  return city ? `${city?.Name}, ${city?.StateAbbreviation} ` : '';
};

export const getCounty = async (countyId) => {
  if (!countyId) {
    return;
  }
  try {
    const county = await fetchValue(`/county/counties`, {
      "lookup.id": countyId
    });
    if (county && county.CountyName) {
      return county.CountyName;
    } else {
      return ""
  } } catch (e) {
    return ""
  }
}

export const getMajor = async (majorId) => {
  const major = await fetchValue(`/major/${majorId}`);
  return major.Title;
};

export const getDegree = async (degreeId) => {
  const degrees = await fetchValue(`/degree-program/degree-programs`);
  for (const i in degrees) {
    if (parseInt(degrees[i].Id) === parseInt(degreeId)) {
      return degrees[i].Name;
    }
  };
  return '';
};

export const getRelation = async (code) => {
  const relationshipTypes = await fetchValue(`relationship-type/relationship-types`);
  for (const i in relationshipTypes) {
    if (parseInt(relationshipTypes[i].Id) === parseInt(code)) {
      return relationshipTypes[i].Description;
    }
  };
  return '';
};

export function DegreePlans({ degrees }) {
    const [value, setValue] = useState([]);
    useEffect(() => {
        (async () => {
            for (const i in degrees) {
                let string =
                    (degrees[i].DegreeId?.value ? 'Degree: ' + await getDegree(degrees[i].DegreeId?.value) + '\n' : '') +
                    (degrees[i].CIPMajorId?.value ? 'Major: ' + await getMajor(degrees[i].CIPMajorId?.value) + '\n' : '') +
                    (degrees[i].CIPMajorId2?.value ? 'Second or Double Major: ' + await getMajor(degrees[i].CIPMajorId2?.value) + '\n' : '') +
                    (degrees[i].GraduationDate?.value ? 'Expected Completion Date: ' + moment(degrees[i].GraduationDate?.value).format('L') : '');
                if (i < degrees.length - 1) string += '\n\n';
                setValue(old => [...old, string]);
            }
        })();
    }, [degrees]);

    return (
        value.map((item) =>
            <>{item}</>
        )
    )
};

const ConvertCode = ({ codeType, code, address, parents, contact, provider, isHS = false, isCollege = false, isEdLevel = false, isAddress = false, isContact = false, isProvider = false }) => {

    const [value, setValue] = useState(null);

    async function describeCode(){
        if (isHS) return await getHS(code);
        if (isCollege){
            if (code === UNLISTED_COLLEGECODE) 
                return "Other, Unlisted College";
            return await getCollege(code);
        }
        if (isEdLevel) {
            return (parents.parent1 ? (await getCode("EDLEVEL", parents.parent1) + ' (Parent 1)\n') : '') +
                (parents.parent2 ? (await getCode("EDLEVEL", parents.parent2) + ' (Parent 2)\n') : '');
        }            
        
        if (isAddress) 
            return ((address.street ? address.street : '') + '\n' + (address.city ? await getCity(address.city) : '') + (address.county ? await getCounty(address.county) : '') + " " + (address.zip ? address.zip : ''));
        
        if (isContact) 
            return  (contact?.FirstName?.value ?? '') + ' ' + (contact?.LastName?.value ?? '') + '\n' +
                (contact?.RelationshipTypeId?.value ? ('(' + await getRelation(contact?.RelationshipTypeId?.value) + ')\n') : '') +
                (contact?.HomePhone?.value ? (contact.HomePhone?.value + ' (Home)\n') : '') +
                (contact?.WorkPhone?.value ? (contact.WorkPhone?.value + ' (Work)\n') : '') +
                (contact?.Email?.value ? (contact.Email?.value + '\n') : '') +
                (contact?.Address?.value ? (contact.Address?.value + '\n') : '') +
                (await getCity(contact?.CityId?.value) || '') + (contact?.Zip?.value ? (contact.Zip?.value) : '');

        if (isProvider) 
            return (provider?.name ? provider?.name + '\n' : '') +
                    (provider?.phone ? provider?.phone + ' (Phone)\n' : '') +
                    (provider?.street ? provider?.street + '\n' : '') +
                    (provider?.city ? await getCity(provider?.city) + provider?.zip + '\n' : '') +
                    (provider?.start ? 'Start Date: ' + moment(provider?.start).format('L') + '\n' : '') +
                    (provider?.isNew ? '✓ New Provider\n' : '') +
                    (provider?.listed ? '✓ Listed with Oregon Department of Human Services\n' : '') +
                    (provider?.registered ? '✓ Registered or certified with the Child Care Division (CCD), Oregon Department of Employment\n' : '') +
                    (provider?.parent ? "✓ Provider is the child's/childrens birth parent or your spouse or partner\n\n" : '\n\n');

        return await getCode(codeType, code);
    }

    useEffect(() => {
        (async () => {            
            const value = await describeCode();            
            setValue(value);
        })();
    }, [code, address, parents, contact, provider]);
    return <>{value}</>;
};

export default ConvertCode
