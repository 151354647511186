import React, { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import Input from "../Input";
import { repeatableFieldName, } from "../Utility/RepeatableField";
import MaskedDatePicker from "../MaskedDatePicker";
import HiddenInput from "../Utility/HiddenInput";
import AnswerLock from "./status/AnswerLock";
import { FieldRow, FieldSlot } from '../Forms/FieldSection';
import { AnswerTypeCollegeProps, UNLISTED_COLLEGECODE } from '../../store/form/fields';
import DegreeDropdown, { DegreeVariant } from '../DataDriven/DegreeDropdown';
import { useEffect, useState } from "react";
import DropdownSearch from "../DropdownSearch";
import apiClient from "../../utils/API";

/**
 * prompt for a college the student has already attended for some period of time
 * 
 * @param {*} param0 
 */
function CollegeHistoryAnswer({ answer, disabled, isPrior = false }) {

    const methods = useFormContext({ mode: "onBlur", });
    const { getValues, watch, setValue } = methods;

    const i = answer.Index;
    const name = answer.FieldKey;
    const currentSchoolCodeFieldName = repeatableFieldName(name, i,  AnswerTypeCollegeProps.CODE);
    const currentCollegeNameOtherFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.UNLISTED_NAME);
    const currentStartDateFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.START);
    const currenteEndDateFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.END);
    const currentDegreeFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.DEGREE);
    const currentCreditsFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.CREDITS);
    const idFieldName = repeatableFieldName(name, i, AnswerTypeCollegeProps.ID);


    const collegeCode = watch(currentSchoolCodeFieldName);
    const isUnlistedCollegeSelected = collegeCode?.value === UNLISTED_COLLEGECODE;

    var [colleges, setColleges] = useState([]);
    useEffect(() => {
        if (!answer.Year)
            return;

        (async () => {
            const options = await apiClient.getOptionsAsListItems(answer.QuestionKey, answer.Year);
            setColleges(options);
        })();
    }, [answer.Year]);

    const isDisabled = disabled || answer.IsLocked;

    // if (answer === null || answer.IsLoading || colleges.length === 0) {
    //     return (
    //         <Fragment key={answer.Id}>
    //         </Fragment>
    //     );
    // }

    return (
        <Fragment key={answer.Id}>
            <div className="rhythm">
                <FieldRow >
                    <FieldSlot>
                        <HiddenInput name={idFieldName} value={answer.Id}/>
                        <HiddenInput name={idFieldName} value={answer.Id}/>
                        <DropdownSearch isRequired={!isUnlistedCollegeSelected} 
                            placeholder="College" 
                            name={currentSchoolCodeFieldName}                     
                            listItems={colleges}
                            includeUnlisted={true} 
                            inputLabel={<>College/University Name <AnswerLock answer={answer} /> </>}
                            variant='outlined' />                                           
                    </FieldSlot>
                    {isUnlistedCollegeSelected &&
                        <FieldSlot>
                            <Input disabled={isDisabled} name={currentCollegeNameOtherFieldName}
                                defaultValue={getValues(currentCollegeNameOtherFieldName)}
                                key={currentCollegeNameOtherFieldName}
                                inputLabel="Unlisted College/University Name"
                                variant='outlined'
                                placeholder='College/University Name'
                                isRequired={true} />
                        </FieldSlot>
                    }


                    <FieldSlot >
                        <FieldRow >
                            <FieldSlot>
                                <MaskedDatePicker disabled={isDisabled} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} minDate={new Date("01-01-1934")} disableFuture={true} name={currentStartDateFieldName} defaultValue={getValues(currentStartDateFieldName)} key={currentStartDateFieldName} label='Date Started' variant='outlined' isRequired={true} />
                            </FieldSlot>
                            {isPrior &&
                                <FieldSlot>
                                    <MaskedDatePicker disabled={isDisabled} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                        minDate={new Date("01-01-1934")} disableFuture={true}
                                        name={currenteEndDateFieldName} defaultValue={getValues(currenteEndDateFieldName)}
                                        key={currenteEndDateFieldName}
                                        label='Date Ended' variant='outlined'
                                        isRequired={true} />
                                </FieldSlot>
                            }
                        </FieldRow>

                    </FieldSlot>
                    <FieldSlot>
                        <FieldRow>
                            <FieldSlot>
                                <DegreeDropdown disabled={isDisabled} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                    name={currentDegreeFieldName} key={currentDegreeFieldName} defaultValue={getValues(currentDegreeFieldName)}
                                    inputLabel='Degree Earned' variant='outlined' valueVariant={DegreeVariant.ByAbbreviation} />
                            </FieldSlot>
                            <FieldSlot>
                                <Input disabled={isDisabled} name={currentCreditsFieldName} defaultValue={getValues(currentCreditsFieldName)} key={currentCreditsFieldName} inputLabel='Credits Earned' variant='outlined' />
                            </FieldSlot>
                        </FieldRow>
                    </FieldSlot>
                </FieldRow>
            </div>
        </Fragment>
    );
};

export default CollegeHistoryAnswer;