import Checkbox from './../../components/Checkbox';
import { useForm, FormProvider } from "react-hook-form";
import React, { useState, useEffect, Fragment } from 'react';
import CollegePicker from '../../components/CollegePicker-modified';
import { FieldSection, FieldRow, FieldSlot } from '../../components/Forms/FieldSection';
import { FIELDS } from '../../store/form/fields';
import FormSection from '../../components/FormSection';
import { useDispatch } from 'react-redux';
import BooleanAnswer from '../../components/answer/BooleanAnswer';
import DecimalAnswer from '../../components/answer/DecimalAnswer';
import Button from '../../components/Button';
import HiddenInput from '../../components/Utility/HiddenInput';

function EditCollegeHistoryInfo({ onChange, onCancel, section }) {

    const year = section.year;
    const commCollegeYear = year;
    const bachelorYear = year;

    const methods = useForm({
        mode: 'onBlur'
    });
    const { handleSubmit, watch, getValues, setValue, register } = methods;

    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const watchNoLetterGrades = watch("NoLetterGrades")
    const watchFinishedCc = watch(FIELDS.FINISHED_COMM_COLLEGE)
    const watchFinishedBd = watch(FIELDS.FINISHED_BACHELORS_DEGREE)
    const watchUndergradGpa = watch(FIELDS.UNDERGRAD_GPA)
    const watchGraduateGpa = watch(FIELDS.GRADUATE_GPA)

    /**
     * actual core work of saving answers
     * @param {*} data 
     */
    async function submitAnswers(data) {
       
        if (watchNoLetterGrades === true) {
            data[FIELDS.UNDERGRAD_GPA] = "0.01"
        }

        await section.saveAnswers(data, dispatch);          
    }

    const onSubmit = async (data) => {
        
        await submitAnswers(data);
        
        if (typeof onChange === 'function') {
            onChange(true);
        }
     
    };

    const onError = (e) => {
        
        const data = getValues();
        submitAnswers(data);
            
    }

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
        }
    }, [])

    const alertUser = e => {
        e.preventDefault()
        e.returnValue = ''
    }

    const [currentCollege, setCurrentCollege] = useState()
    const [pastCollege, setPastCollege] = useState()

    function collegePickerCallback(current, past) {
        setPastCollege(past?.length > 0)
        setCurrentCollege(current?.length > 0)
    }

    //=====( NO COLLEGE HISTORY LOGIC )
    const [noCollegeHistory, setNoCollegeHistory] = useState(null);
    const [hideNoCollegeHistory, setHideNoCollegeHistory] = useState(true);

    useEffect(() => {
        let noHistoryAnswer = section.getAnswerByField(FIELDS.COLLEGE_HISTORY_COMPLETE).Response;
        setNoCollegeHistory(noHistoryAnswer ? true : null)
    }, [])

    function handleToggle() {
        setNoCollegeHistory(noCollegeHistory ? null : true)
    }

    useEffect(() => {
        if (noCollegeHistory !== true
            && (currentCollege
                || pastCollege
                || watchFinishedCc === 'True'
                || watchFinishedBd === 'True'
                || watchUndergradGpa
                || watchGraduateGpa)) {
            setNoCollegeHistory(false);
            setHideNoCollegeHistory(true);
        }
        else {
            setHideNoCollegeHistory(false);
        }
    }, [currentCollege, pastCollege, watchFinishedCc, watchFinishedBd, watchUndergradGpa, watchGraduateGpa])

    //=====( RENDER RETURN )

    return (
        <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit, onError)} >
                <FormSection title='College History' isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} onCancelClick={onCancel} loading={loading}>
                    <HiddenInput
                        name={FIELDS.COLLEGE_HISTORY_COMPLETE}
                        value={noCollegeHistory}
                    />
                    {!hideNoCollegeHistory ?
                        <FieldSection>
                            <FieldSlot>
                                <Checkbox
                                    useController={false}
                                    onChange={handleToggle}
                                    value={noCollegeHistory}
                                    label={'I have no college history.'}
                                />
                            </FieldSlot>
                        </FieldSection>
                        : null}

                    {!noCollegeHistory ?
                        <Fragment>
                            <FieldSection>
                                <FieldRow>
                                    <FieldSlot>
                                        List all colleges attended, entering each college once, even if there are gaps in attendance.
                                        Enter the first date attended as the <i>Date Started</i> and enter the last date attended as the <i>Date Ended</i>.
                                    </FieldSlot>
                                </FieldRow>
                            </FieldSection>
                            <div className="rhythm">
                                <CollegePicker collegePickerCallback={collegePickerCallback} section={section} />
                            </div>

                            <div className='divider' />

                            <FieldSection isRequired title={"Will you have finished two academic years towards a degree or certificate at community college by July 1, " + commCollegeYear + "?"}>
                                <FieldRow>
                                    <FieldSlot>
                                        <BooleanAnswer answer={section.getAnswerByField(FIELDS.FINISHED_COMM_COLLEGE)} value={watchFinishedCc} isRequired={true} />
                                    </FieldSlot>
                                </FieldRow>
                            </FieldSection>

                            <FieldSection isRequired title={"Will you have earned your Bachelor’s degree by fall " + bachelorYear + "?"}>
                                <FieldRow>
                                    <FieldSlot>
                                        <BooleanAnswer answer={section.getAnswerByField(FIELDS.FINISHED_BACHELORS_DEGREE)} value={watchFinishedBd} isRequired={true} />
                                    </FieldSlot>
                                </FieldRow>
                            </FieldSection>

                            <FieldSection title="Cumulative Undergraduate GPA" withDivider={true} isRequired={currentCollege || pastCollege}>
                                <FieldRow>
                                    <FieldSlot>
                                        <DecimalAnswer disabled={watchNoLetterGrades}
                                            answer={section.getAnswerByField(FIELDS.UNDERGRAD_GPA)}
                                            isRequired={currentCollege || pastCollege}
                                            inputLabel='Undergraduate GPA' />
                                    </FieldSlot>
                                    <FieldSlot container alignItems="flex-end">
                                        <div>
                                            <Checkbox name='NoLetterGrades' label="My college did not assign grades." />
                                        </div>
                                    </FieldSlot>
                                </FieldRow>
                            </FieldSection>
                            <FieldSection title="Cumulative Graduate GPA" withDivider={true}>
                                <FieldRow>
                                    <FieldSlot>
                                        <DecimalAnswer disabled={watchNoLetterGrades} answer={section.getAnswerByField(FIELDS.GRADUATE_GPA)} inputLabel='Graduate GPA' />
                                    </FieldSlot>
                                </FieldRow>
                            </FieldSection>
                        </Fragment>
                        : null}

                    {noCollegeHistory ?
                        <Fragment>
                            <FieldSection>
                                You have indicated you have never attended college.
                                <FieldSlot>
                                    <Button label='Provide College history' onClick={handleToggle}
                                    />
                                </FieldSlot>
                            </FieldSection>
                        </Fragment>
                        : null}

                </FormSection>
            </form>
        </FormProvider>
    );
}

export default EditCollegeHistoryInfo;
