import { Alert } from '@material-ui/lab';
import CollegePicker from "../../CollegePicker-modified";
import { FieldRow } from "../../Forms/FieldSection";
import { FIELDS } from "../../../store/form/fields";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from 'react-redux';
import FormSection from '../../FormSection';
import BooleanAnswer from '../BooleanAnswer';
import DecimalAnswer from '../DecimalAnswer';
import CertifyAnswer from '../CertifyAnswer';


/**
 * child care college history questions
 * @returns {Component}
 */
function CollegeHistorySection({ status, onChange, section }) {
    const methods = useForm({ mode: "onBlur", });
    const { errors, formState, getValues, setValue, handleSubmit, watch } = methods;
    const dispatch = useDispatch();

    const onSubmit = (data, e) => {
        section.saveAnswers(data, dispatch);        
    }
    const onError = async (data, e) => {
        //just display validations is enough
    }

    return (
        <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit, onError)} >
                <FormSection title='College History' isEditable={true} onSaveClick={handleSubmit(onSubmit, onError)} loading={section.isAnswerLoading} status={status} displayOnLoad={true}>

                    <Alert severity="success" color="warning" className="alert alert--success">
                        All college history is required
                    </Alert>


                    <CollegePicker section={section}/>

                    <div className="input-field" alignItems="baseline">                   
                        <CertifyAnswer answer={section.getAnswerByField(FIELDS.COLLEGE_HISTORY_COMPLETE)} 
                            isRequired={false} 
                            label={<>I have no college history / I have entered all my college history. <span style={{color:'red'}}>*</span> </> }/>
                    </div>

                    <div className="input-field">                     
                        <BooleanAnswer answer={section.getAnswerByField(FIELDS.FINISHED_COMM_COLLEGE)} isRequired={true} 
                            label={<>Will you have finished two academic years towards a degree or certificate at community college by July 1, {section.year}?</>} />
                    </div>


                    <div container className="input-field">                  
                        <BooleanAnswer answer={section.getAnswerByField(FIELDS.FINISHED_BACHELORS_DEGREE)} isRequired={true} 
                            label={<>Will you have earned your Bachelor's degree by Fall {section.year}?</>}/>
                    </div>


                    <div className="input-field rhythm">
                        <h4 className="heading heading--h5">Cumulative GPA(s)</h4>
                        <FieldRow className="rhythm">
                            <DecimalAnswer answer={section.getAnswerByField(FIELDS.UNDERGRAD_GPA)} inputLabel="Undergraduate GPA" />
                            <DecimalAnswer answer={section.getAnswerByField(FIELDS.GRADUATE_GPA)} inputLabel="Graduate GPA" />
                        </FieldRow>
                    </div>


                </FormSection>
            </form>
        </FormProvider>
    );
}

export default CollegeHistorySection;